import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/navbar";
import PropertyTwo from "../components/propertyTwo";
import WantedProperty from "../components/wantedProperty";
import Footer from "../components/footer";
import Spinner from "../common/loading-spinner";
import { db } from "../config";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  startAfter,
  limit,
  orderBy,
  where,
} from "firebase/firestore";
import bg3 from "../assect/images/website/12.jpg";
import { propertyData } from "../data/data";
import RoutesEnums from "../enums/routes.enums";
import "../App.css";

export default function Buy() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [featuredData, setFeaturedData] = useState([]);
  const [wantedData, setWantedData] = useState([]);
  const [hasPropertyCreationAccess, setHasPropertyCreationAccess] =
    useState(false);

  useEffect(() => {
    setLoading(true);
    fetchData();
    authorizedUser(localStorage.getItem("userID"));
    // fetchFeaturedData();
    // fetchWantedData();
  }, []);

  const authorizedUser = async (userId) => {
    const userRef = doc(db, "admin-access", "authorizedUsers");

    try {
      const docSnapshot = await getDoc(userRef);
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        const uid = JSON.parse(userId);
        const access = data[uid];
        //console.log("data:", data[uid]);
        if (access[1] === "all" || access[1] === "properties") {
          setHasPropertyCreationAccess(true);
        }
      } else {
        //console.log("authorizedUsers document does not exist");
        setHasPropertyCreationAccess(false);
        return false;
      }
    } catch (error) {
      console.error("Error checking user existence:", error);
      return false;
    }
  };

  const fetchData = async () => {
    // let q = query(collection(db, "properties"));

    let q1 = query(
      collection(db, "properties"),
      where("propertyCatogery", "==", "Featured"), // Filter by categoryType
      limit(6) // Limit the results to 6 items
    );

    let q2 = query(
      collection(db, "properties"),
      where("propertyCatogery", "==", "Wanted"), // Filter by categoryType
      limit(6) // Limit the results to 6 items
    );

    const querySnapshot1 = await getDocs(q1);
    const querySnapshot2 = await getDocs(q2);

    const newData1 = querySnapshot1.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    const newData2 = querySnapshot2.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    // const filteredFeaturedData = newData.filter(
    //   (item) => item.propertyCatogery === "Featured"
    // );

    // const filteredWantedData = newData.filter(
    //   (item) => item.propertyCatogery === "Wanted"
    // );

    //console.log("Data from API: (newData1)", newData1);
    //console.log("Data from API: (newData2)", newData2);
    setFeaturedData((prevAllData) => [...prevAllData, ...newData1]);
    setWantedData((prevAllData) => [...prevAllData, ...newData2]);
    setLoading(false);
  };

  // const fetchFeaturedData = async () => {
  //   let q = query(collection(db, "addProperty"), limit(6));

  //   const querySnapshot = await getDocs(q);

  //   const newData = querySnapshot.docs.map((doc) => ({
  //     ...doc.data(),
  //     id: doc.id,
  //   }));

  //   console.log("Data from API of Featured data is: ", newData);
  //   setFeaturedData((prevAllData) => [...prevAllData, ...newData]);
  //   console.log("After setting featured data: ", featuredData);
  //   setLoading(false);
  // };

  // const fetchWantedData = async () => {
  //   let q = query(collection(db, "addWantedProperty"), limit(6));

  //   const querySnapshot = await getDocs(q);

  //   const newData = querySnapshot.docs.map((doc) => ({
  //     ...doc.data(),
  //     id: doc.id,
  //   }));

  //   console.log("Data from API of Wanted data is: ", newData);
  //   setWantedData((prevAllData) => [...prevAllData, ...newData]);
  //   console.log("After setting wanted data: ", wantedData);
  //   setLoading(false);
  // };

  return (
    <>
      {loading && <Spinner />}
      <Navbar
        navClass="defaultscroll sticky"
        logolight={true}
        menuClass="navigation-menu nav-left nav-light"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${bg3})` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
                  Buy Church
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      {/* {localStorage.getItem("accessToken") && (
        <div className="row justify-content-center pt-4">
          <button
            onClick={() => {
              navigate(RoutesEnums.ADD_PROPERTY);
            }}
            className="btn btn-primary"
            style={{ width: "200px" }}
          >
            + Add Property
          </button>
        </div>
      )} */}

      {localStorage.getItem("accessToken") && (
        <div className="row justify-content-center pt-4">
          {hasPropertyCreationAccess ? (
            <button
              onClick={() => {
                navigate(RoutesEnums.ADD_PROPERTY);
              }}
              className="btn btn-primary"
              style={{ width: "200px" }}
            >
              + Add Property
            </button>
          ) : null}
        </div>
      )}

      <section className="less-padding-section">
        <div className="container">
          <PropertyTwo title="Featured Churches" data={featuredData} />
        </div>

        <div className="container mt-100 mt-60">
          <WantedProperty title="Wanted Churches" data={wantedData} />
        </div>
      </section>
      <Footer />
    </>
  );
}
