import React from "react";
import { Link } from "react-router-dom";

export default function WantedProperty(props) {
  let propertyData = props.data;

  //console.log("Wanted Property data from properties page is: ", propertyData);

  return (
    <>
      <div className="row justify-content-center">
        <div className="col">
          <div className="section-title text-center mb-4 pb-2">
            <h4 className="title mb-3">{props.title}</h4>
          </div>
        </div>
      </div>

      <div className="row g-4 mt-0">
        {propertyData.length > 0 ? (
          propertyData.map((item, index) => {
            return (
              <div className="col-lg-6 col-12" key={index}>
                <div className="card property property-list border-0 shadow position-relative overflow-hidden rounded-3">
                  <div className="d-md-flex">
                    <div
                      className="property-image position-relative overflow-hidden shadow flex-md-shrink-0 rounded-3 m-2"
                      style={{
                        aspectRatio: "9/16",
                      }}
                    >
                      <img
                        src={item.image}
                        className="img-fluid h-100 w-100"
                        alt=""
                      />
                    </div>
                    <div className="card-body content p-3">
                      <Link
                        to={`/wanted-property-detail/${item.id}`}
                        className="title fs-5 text-dark fw-medium"
                      >
                        {item.address}
                      </Link>

                      <ul className="list-unstyled mt-3 py-3 border-top border-bottom d-flex align-items-center justify-content-between">
                        <li className="d-flex align-items-center me-3">
                          <i className="mdi mdi-arrow-expand-all fs-5 me-2 text-primary"></i>
                          <span className="text-muted">
                            {item.building} ± sq ft
                          </span>
                        </li>

                        <li className="d-flex align-items-center me-3">
                          <i className="mdi mdi-currency-usd fs-5 me-2 text-primary"></i>
                          <span className="text-muted">
                            Price $ {item.price}
                          </span>
                        </li>
                      </ul>
                      <ul className="list-unstyled d-flex justify-content-between mt-2 mb-0">
                        <li className="list-inline-item mb-0">
                          <span className="text-muted">Customer</span>
                          <p className="fw-medium mb-0">{item.agent}</p>
                        </li>
                        <li className="list-inline-item mb-0">
                          <span className="text-muted">Phone</span>
                          <p className="fw-medium mb-0">{item.phone}</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div>No Wanted Properties</div>
        )}

        {propertyData.length >= 6 && (
          <div className="col-12 mt-4 pt-2">
            <div className="text-center">
              <Link to="/list" className="mt-3 fs-6 text-primary">
                View More <i className="mdi mdi-arrow-right align-middle"></i>
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
