import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/navbar";
import bg4 from "../assect/images/bg/04.jpg";
import Footer from "../components/footer";
import "../App.css";
import Broker from "./broker";
import { FiMail } from "react-icons/fi";

export default function Careers() {
  return (
    <>
      <Navbar
        navClass="defaultscroll sticky"
        logolight={true}
        menuClass="navigation-menu nav-left nav-light"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${bg4})` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
                  Careers
                </h5>
              </div>
            </div>
          </div>
          <div className="position-middle-bottom">
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                <li className="breadcrumb-item">
                  <Link to="/">PierTop</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Careers
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <Broker />
        </div>
        <div className="container mt-100 mt-60">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title text-center">
                <h4 className="title mb-3">
                  We are growing and always looking for talented Real estate
                  Agent to join our team.
                </h4>
                <div className="mt-4 pt-2">
                  <Link to="/contactus" className="btn btn-pills btn-primary">
                    <span className="h5 mb-0 me-1">
                      <FiMail className="fea icon-sm" />
                    </span>{" "}
                    Contact us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
