import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../components/navbar";
import bg4 from "../assect/images/bg/04.jpg";
import Footer from "../components/footer";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
//import { Progress } from "antd";
import "../App.css";
import { db } from "../config";
//import { storage } from "../config";
import { collection, addDoc } from "firebase/firestore";
//import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import Spinner from "../common/loading-spinner";
import { toast } from "react-toastify";
import { toastUtil } from "../utils/toast.utils";
import RoutesEnums from "../enums/routes.enums";

export default function AddWantedProperty() {
  let navigate = useNavigate();
  //const MAX_IMG_SIZE = 5;
  const currentDate = new Date();

  //const [percent, setPercent] = useState(0);
  //const [showPercentBar, setShowPercentBar] = useState(false);
  const [desc, setDesc] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    location: "",
    landArea: "",
    price: "",
    propertyType: "Select Type",
    building: "",
    seating: "",
    parking: "",
    customer: "",
    phone: "",
    image:
      "https://firebasestorage.googleapis.com/v0/b/piertop-jaques.appspot.com/o/blogImages%2Fchurch.jpg?alt=media&token=71036057-5216-4c03-b3e1-75131251f510",
    additionalComments: "",
  });

  //   const handleUpload = () => {
  //     setLoading(true);
  //     return new Promise((resolve, reject) => {
  //       if (!formData.image) {
  //         alert("Please choose a file first!");
  //         reject("No file selected");
  //         return;
  //       }

  //       const storageRef = ref(storage, `/blogImages/${formData.imageName}`);
  //       const uploadTask = uploadBytesResumable(storageRef, formData.image);

  //       uploadTask.on(
  //         "state_changed",
  //         (snapshot) => {
  //           const percent = Math.round(
  //             (snapshot.bytesTransferred / snapshot.totalBytes) * 100
  //           );
  //           setShowPercentBar(true);
  //           setPercent(percent);
  //         },
  //         (err) => {
  //           console.error(err);
  //           setLoading(false);
  //           reject(err);
  //         },
  //         () => {
  //           getDownloadURL(uploadTask.snapshot.ref)
  //             .then((url) => {
  //               console.log(url);
  //               resolve(url);
  //             })
  //             .catch((err) => {
  //               console.error(err);
  //               reject(err);
  //             });
  //         }
  //       );
  //     });
  //   };

  const handleChange = (e) => {
    let { name, value, files } = e?.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      formData.additionalComments = desc;
      //console.log("Form data is: ", formData);
      const docRef = await addDoc(collection(db, "addWantedProperty"), {
        location: formData.location,
        landArea: formData.landArea,
        price: formData.price,
        propertyType: formData.propertyType,
        building: formData.building,
        seating: formData.seating,
        parking: formData.parking,
        customer: formData.customer,
        phone: formData.phone,
        image: formData.image,
        date: currentDate,
        additionalComments: formData.additionalComments,
      });
      //console.log("Document written with ID: ", docRef.id);
    } catch (error) {
      setLoading(false);
      toast.error(error.message, toastUtil);
      console.error("Error adding document: ", error);
    } finally {
      toast.success("Property Added", toastUtil);
      setLoading(false);
      //setFormData(null);
      navigate(RoutesEnums.BUY);
    }
  };

  return (
    <>
      {loading && <Spinner />}
      <Navbar
        navClass="defaultscroll sticky"
        logolight={true}
        menuClass="navigation-menu nav-left nav-light"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${bg4})` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container end-section">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
                  Add Wanted Property
                </h5>
              </div>
            </div>
          </div>
          <div className="position-middle-bottom">
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                <li className="breadcrumb-item">
                  <Link to="/">Piertop</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Add Wanted Property
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section className="section pb-0">
        <div className="container end-section">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="p-4 rounded-3 shadow">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12">
                      <div className="mb-3">
                        <label className="form-label">
                          Location <span className="text-danger">*</span>
                        </label>
                        <input
                          name="location"
                          id="property-location"
                          type="text"
                          className="form-control"
                          placeholder="Location"
                          onChange={handleChange}
                          value={formData.location}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label">
                          Land Area <span className="text-danger">*</span>
                        </label>
                        <input
                          name="landArea"
                          id="property-landArea"
                          type="text"
                          className="form-control"
                          placeholder="Area in acres"
                          onChange={handleChange}
                          value={formData.landArea}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-5">
                      <div className="mb-3">
                        <label className="form-label">
                          Price Range <span className="text-danger">*</span>
                        </label>
                        <input
                          name="price"
                          id="property-price"
                          type="text"
                          className="form-control"
                          placeholder="Price range in dollars"
                          onChange={handleChange}
                          value={formData.price}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-3">
                        <label className="form-label">
                          Type <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-select form-control border mt-2"
                          id="propertyType"
                          name="propertyType"
                          onChange={handleChange}
                          value={formData.propertyType}
                          required
                        >
                          <option value="Sale">Sale</option>
                          <option value="Lease">Lease</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label">
                          Building <span className="text-danger">*</span>
                        </label>
                        <input
                          name="building"
                          id="property-building"
                          type="text"
                          className="form-control"
                          placeholder="Area in sqft"
                          onChange={handleChange}
                          value={formData.building}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label">Seating</label>
                        <input
                          name="seating"
                          id="property-seating"
                          type="text"
                          className="form-control"
                          placeholder="Seating capacity"
                          onChange={handleChange}
                          value={formData.seating}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label">Parking</label>
                        <input
                          name="parking"
                          id="property-parking"
                          type="text"
                          className="form-control"
                          placeholder="Parking capacity"
                          onChange={handleChange}
                          value={formData.parking}
                        />
                      </div>
                    </div>

                    <div className="col-md-5">
                      <div className="mb-3">
                        <label className="form-label">
                          Customer <span className="text-danger">*</span>
                        </label>
                        <input
                          name="customer"
                          id="property-customer"
                          type="text"
                          className="form-control"
                          placeholder="Customer Name"
                          onChange={handleChange}
                          value={formData.customer}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-7">
                      <div className="mb-3">
                        <label className="form-label">
                          Phone Number <span className="text-danger">*</span>
                        </label>
                        <input
                          name="phone"
                          id="property-phone"
                          type="text"
                          className="form-control"
                          placeholder="Phone Number"
                          onChange={handleChange}
                          value={formData.phone}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="mb-3">
                        <label className="form-label">
                          Additional Comments
                          <span className="text-danger">*</span>
                        </label>
                        <ReactQuill
                          className="h-[500px]"
                          theme="snow"
                          name="additionalComments"
                          onChange={setDesc}
                          value={desc}
                          required
                        />
                      </div>
                    </div>

                    {/* <div className="col-12">
                      <div className="mb-3">
                        <label className="form-label">Upload Image</label>
                        <input
                          name="image"
                          id="blog-image"
                          type="file"
                          accept=".jpg,.jpeg,.png"
                          className="form-control"
                          onChange={handleChange}
                          defaultValue={formData.image}
                          required
                        />
                        {showPercentBar && (
                          <span>
                            <Progress percent={percent} size="small" />
                          </span>
                        )}
                      </div>
                    </div> */}
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="d-grid">
                        <button
                          type="submit"
                          id="property-submit"
                          name="property-submit"
                          className="btn btn-primary"
                        >
                          Add Wanted Property
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="container-fluid mt-100 mt-60">
                <div className="row">
                    <div className="col-12 p-0">
                        <div className="card map border-0">
                            <div className="card-body p-0">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin" style={{border:"0" }} title="Townter" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
      </section>
      <Footer />
    </>
  );
}
