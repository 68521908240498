import React from 'react';
import {ThreeDots} from 'react-loader-spinner';

const SectionSpinner = () => (
    <div className="justify-content-center mt-5" style={{display:"flex"}}>
    <ThreeDots
        visible={true}
        height="60"
        width="60"
        color="#0c1d56"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
    />
    </div>
);

export default SectionSpinner;