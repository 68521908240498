import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import pierTopBlue from "../assect/images/Logo/PT (1).png";
import pierTopWhite from "../assect/images/Logo/white_logo.png";
// import pierTopBlue from "../assect/images/PierTop-blue.png";
//import pierTopWhite from "../assect/images/PierTop-white.png";
import { FiSearch, FiUser, CiLogout } from "../assect/icons/vander";
import "./navbar.css";
import "../App.css";
import { logout } from "../pages/auth/auth-functions";
import RoutesEnums from "../enums/routes.enums";

export default function Navbar({ navClass, menuClass }) {
  const [scroll, setScroll] = useState(false);
  const [isMenu, setisMenu] = useState(false);
  const [modal, setModal] = useState(false);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const iconCheckLoggedIn = () => {
    return localStorage.getItem("accessToken") ? (
      <Link
        onClick={() => {
          logout();
        }}
        to={RoutesEnums.LOGIN}
        className="btn btn-sm btn-icon btn-pills btn-primary"
      >
        <CiLogout className="icons" />
      </Link>
    ) : (
      <Link
        to={RoutesEnums.LOGIN}
        className="btn btn-sm btn-icon btn-pills btn-primary"
      >
        <FiUser className="icons" />
      </Link>
    );
  };

  useEffect(() => {
    activateMenu();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
    const closeDropdown = () => {
      setModal(false);
    };
    document.addEventListener("mousedown", closeDropdown);
    window.scrollTo(0, 0);
  }, []);

  var mybutton = document.getElementById("back-to-top");
  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (mybutton != null) {
      if (
        document.body.scrollTop > 500 ||
        document.documentElement.scrollTop > 500
      ) {
        mybutton.style.display = "block";
      } else {
        mybutton.style.display = "none";
      }
    }
  }

  // Toggle menu
  const toggleMenu = () => {
    setisMenu(!isMenu);
    if (document.getElementById("navigation")) {
      const anchorArray = Array.from(
        document.getElementById("navigation").getElementsByTagName("a")
      );
      anchorArray.forEach((element) => {
        element.addEventListener("click", (elem) => {
          const target = elem.target.getAttribute("href");
          if (target !== "") {
            if (elem.target.nextElementSibling) {
              var submenu = elem.target.nextElementSibling.nextElementSibling;
              submenu.classList.toggle("open");
            }
          }
        });
      });
    }
  };
  function getClosest(elem, selector) {
    // Element.matches() polyfill
    if (!Element.prototype.matches) {
      Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function (s) {
          var matches = (this.document || this.ownerDocument).querySelectorAll(
              s
            ),
            i = matches.length;
          while (--i >= 0 && matches.item(i) !== this) {}
          return i > -1;
        };
    }

    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
      if (elem.matches(selector)) return elem;
    }
    return null;
  }

  function activateMenu() {
    var menuItems = document.getElementsByClassName("sub-menu-item");
    if (menuItems) {
      var matchingMenuItem = null;
      for (var idx = 0; idx < menuItems.length; idx++) {
        if (menuItems[idx].href === window.location.href) {
          matchingMenuItem = menuItems[idx];
        }
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add("active");

        var immediateParent = getClosest(matchingMenuItem, "li");

        if (immediateParent) {
          immediateParent.classList.add("active");
        }

        var parent = getClosest(immediateParent, ".child-menu-item");
        if (parent) {
          parent.classList.add("active");
        }

        var parent = getClosest(parent || immediateParent, ".parent-menu-item");

        if (parent) {
          parent.classList.add("active");

          var parentMenuitem = parent.querySelector(".menu-item");
          if (parentMenuitem) {
            parentMenuitem.classList.add("active");
          }

          var parentOfParent = getClosest(parent, ".parent-parent-menu-item");
          if (parentOfParent) {
            parentOfParent.classList.add("active");
          }
        } else {
          var parentOfParent = getClosest(
            matchingMenuItem,
            ".parent-parent-menu-item"
          );
          if (parentOfParent) {
            parentOfParent.classList.add("active");
          }
        }
      }
    }
  }
  return (
    <>
      <header
        id="topnav"
        className={`${scroll ? "nav-sticky" : ""} ${navClass}`}
      >
        <div className="container">
          {/* {logolight === true ? (
            <Link className="logo" to="/">
              <span className="logo-light-mode">
                <img src={logoDark} className="l-dark" alt="" />
                <img src={logoLight} className="l-light" alt="" />
              </span>
              <img src={logoLight} className="logo-dark-mode" alt="" />
            </Link>
          ) : (
            <Link className="logo" to="/">
              <img src={logoDark} className="logo-light-mode" alt="" />
              <img src={logoLight} className="logo-dark-mode" alt="" />
            </Link>
          )} */}

          <Link className="logo" to="/">
            <span className="logo-light-mode">
              <a className={`${navClass}`}>
                <img
                  style={{ height: "75px", width: "75px", marginTop: "15px" }}
                  src={
                    scroll || windowSize[0] <= 990 ? pierTopBlue : pierTopWhite
                  }
                />
              </a>
            </span>
          </Link>

          <div className="menu-extras">
            <div className="menu-item">
              <Link
                className={`navbar-toggle ${isMenu ? "open" : ""}`}
                id="isToggle"
                onClick={() => toggleMenu()}
              >
                <div className="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </Link>
            </div>
          </div>

          <div className="wrapper-class">
            <ul className="buy-button list-inline mb-0">
              {/* <li className="list-inline-item ps-1 mb-0">
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn btn-sm btn-icon btn-pills btn-primary dropdown-toggle"
                    onClick={() => setModal(!modal)}
                  >
                    <FiSearch className="icons" />
                  </button>
                  <div
                    className={`${
                      modal === true ? "show" : ""
                    } dropdown-menu dd-menu dropdown-menu-start bg-white rounded-3 border-0 mt-3 p-0 right-0`}
                    style={{ width: "240px", right: "0" }}
                  >
                    <div className="search-bar">
                      <div id="itemSearch" className="menu-search mb-0">
                        <form
                          role="search"
                          method="get"
                          id="searchItemform"
                          className="searchform"
                        >
                          <input
                            type="text"
                            className="form-control rounded-3 border"
                            name="s"
                            id="searchItem"
                            placeholder="Search..."
                          />
                          <input
                            type="submit"
                            id="searchItemsubmit"
                            value="Search"
                          />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </li> */}
              <li className="list-inline-item ps-1 mb-0">
                {iconCheckLoggedIn()}
              </li>
            </ul>
            <div id="navigation" style={{ display: isMenu ? "block" : "none" }}>
              <ul className={menuClass}>
                <li className="has-submenu parent-menu-item">
                  <Link to={RoutesEnums.SERVICES}>Services</Link>
                  {/* <span className="menu-arrow"></span>
                <ul className="submenu">
                  <li>
                    <Link to="/" className="sub-menu-item">
                      Brokerage
                    </Link>
                  </li>
                  <li>
                    <Link to="/index-two" className="sub-menu-item">
                      Consulting
                    </Link>
                  </li>
                </ul> */}
                </li>

                {/* <li>
                <Link to="/buy" className="sub-menu-item">
                  Buy
                </Link>
              </li>

              <li>
                <Link to="/sell" className="sub-menu-item">
                  Sell
                </Link>
              </li> */}

                <li className="has-submenu parent-parent-menu-item">
                  <Link to={RoutesEnums.BUY}>Properties</Link>
                </li>

                <li className="has-submenu parent-parent-menu-item">
                  <Link to={RoutesEnums.BLOGS}>Insight & Research</Link>
                </li>

                {/* <li className="has-submenu parent-parent-menu-item">
 </li>
            */}
                <li className="has-submenu parent-parent-menu-item">
                  <Link to={RoutesEnums.INSIGHTS_AND_RESEARCH}>Tools</Link>
                </li>

                <li className="has-submenu parent-parent-menu-item">
                  <Link to="/aboutus">About Us</Link>
                </li>

                {/* <li className="has-submenu parent-parent-menu-item">
                <Link to="#">Pages</Link>
                <span className="menu-arrow"></span>
                <ul className="submenu">
                  <li>
                    <Link to="/aboutus" className="sub-menu-item">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/features" className="sub-menu-item">
                      Features
                    </Link>
                  </li>
                  <li>
                    <Link to="/pricing" className="sub-menu-item">
                      Pricing
                    </Link>
                  </li>
                  <li>
                    <Link to="/faqs" className="sub-menu-item">
                      Faqs
                    </Link>
                  </li>
                  <li className="has-submenu parent-menu-item">
                    <Link to="#"> Auth Pages </Link>
                    <span className="submenu-arrow"></span>
                    <ul className="submenu">
                      <li>
                        <Link to="/auth-login" className="sub-menu-item">
                          Login
                        </Link>
                      </li>
                      <li>
                        <Link to="/auth-signup" className="sub-menu-item">
                          Signup
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/auth-reset-password"
                          className="sub-menu-item"
                        >
                          Reset Password
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="has-submenu parent-menu-item">
                    <Link to="#"> Utility </Link>
                    <span className="submenu-arrow"></span>
                    <ul className="submenu">
                      <li>
                        <Link to="/terms" className="sub-menu-item">
                          Terms of Services
                        </Link>
                      </li>
                      <li>
                        <Link to="/privacy" className="sub-menu-item">
                          Privacy Policy
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="has-submenu parent-menu-item">
                    <Link to="#"> Blog </Link>
                    <span className="submenu-arrow"></span>
                    <ul className="submenu">
                      <li>
                        <Link to="/blogs" className="sub-menu-item">
                          {" "}
                          Blogs
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog-sidebar" className="sub-menu-item">
                          {" "}
                          Blog Sidebar
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog-detail" className="sub-menu-item">
                          {" "}
                          Blog Detail
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="has-submenu parent-menu-item">
                    <Link to="#"> Special </Link>
                    <span className="submenu-arrow"></span>
                    <ul className="submenu">
                      <li>
                        <Link to="/comingsoon" className="sub-menu-item">
                          Comingsoon
                        </Link>
                      </li>
                      <li>
                        <Link to="/maintenance" className="sub-menu-item">
                          Maintenance
                        </Link>
                      </li>
                      <li>
                        <Link to="/error" className="sub-menu-item">
                          404! Error
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li> */}

                <li>
                  <Link to="/contactus" className="sub-menu-item">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
