import { Link } from "react-router-dom";
import bg3 from "../../assect/images/bg/03.jpg";
import logo from "../../assect/images/logo-icon-80.png";
import React, { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { toast } from "react-toastify";
import { toastUtil } from "../../utils/toast.utils";

export default function ResetPassword() {
  const [email, setEmail] = useState("");
  const [rec_error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleResetPassword = async (e) => {
    const auth = getAuth();
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setSuccess(true);
      //console.log("success", success);
      toast.success("Password reset link sent to the email", toastUtil);
    } catch (error) {
      setError(error.message);
      toast.error("Error sending the password reset link", toastUtil);
    }
  };
  return (
    <section
      className="bg-home zoom-image d-flex align-items-center"
      style={{
        backgroundImage: `url(${bg3})`,
      }}
    >
      <div className="bg-overlay bg-gradient-overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className="p-4 bg-white rounded-3 shadow-md mx-auto w-100"
              style={{ maxWidth: "400px" }}
            >
              <form>
                <Link to="/">
                  <img src={logo} className="mb-4 d-block mx-auto" alt="" />
                </Link>
                <h5 className="mb-3">Reset your password</h5>

                <p className="text-muted">
                  Please enter your email address. You will receive a link to
                  create a new password via email.
                </p>

                <div className="form-floating mb-3">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label htmlFor="floatingInput">Email address</label>
                </div>

                <button
                  className="btn btn-primary w-100"
                  type="submit"
                  onClick={handleResetPassword}
                >
                  Send
                </button>

                <div className="col-12 text-center mt-3">
                  <span>
                    <span className="text-muted me-2">
                      Remember your password ?{" "}
                    </span>{" "}
                    <Link to="/auth-login" className="text-dark fw-medium">
                      Sign in
                    </Link>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
