import React, { useState, useEffect, useRef } from "react";
import Chart from "chart.js/auto"; // Import chart.js library
import "../App.css";
import { toast } from "react-toastify";
import { toastUtil } from "../utils/toast.utils";

const LoanCalculator = () => {
  const [loanAmount, setLoanAmount] = useState(0);
  const [interestRate, setInterestRate] = useState(0.0);
  const [loanTenure, setLoanTenure] = useState(0);

  const [loanEMI, setLoanEMI] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const [myChart, setMyChart] = useState(null);

  const loanAmountInput = useRef(null);
  const interestRateInput = useRef(null);
  const loanTenureInput = useRef(null);

  let interest = interestRate / 12 / 100;

  useEffect(() => {
    const ctx = document.getElementById("myChart").getContext("2d");
    const chart = new Chart(ctx, {
      type: "pie",
      data: {
        labels: ["Total Interest", "Principal Loan Amount"],
        datasets: [
          {
            data: [totalInterest, loanAmount],
            backgroundColor: ["#e63946", "#14213d"],
            borderWidth: 0,
          },
        ],
      },
    });
    setMyChart(chart);

    return () => {
      // Clean up the chart instance when component unmounts
      chart.destroy();
    };
  }, []); // Run only on initial render

  const checkValues = () => {
    let loanAmountValue = loanAmountInput.current.value;
    let interestRateValue = interestRateInput.current.value;
    let loanTenureValue = loanTenureInput.current.value;

    let regexNumber = /^[0-9]+$/;
    let regexDecimalNumber = /^(\d*\.)?\d+$/;
    if (
      !loanAmountValue.match(regexNumber) ||
      !loanTenureValue.match(regexNumber) ||
      !interestRateValue.match(regexDecimalNumber)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const calculateEMI = (loanAmount, annualInterestRate, loanTenureInYears) => {
    if (checkValues()) {
      const monthlyInterestRate = annualInterestRate / 12 / 100; // Convert annual interest rate to monthly and decimal
      const numberOfPayments = loanTenureInYears * 12; // Convert loan tenure to months

      const emi =
        (loanAmount *
          monthlyInterestRate *
          Math.pow(1 + monthlyInterestRate, numberOfPayments)) /
        (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);

      // console.log("EMI calculated is: ", emi);
      return emi;
    } else {
      toast.error("Please enter valid values!", toastUtil);
      setLoanAmount(0);
      setInterestRate(0.0);
      setLoanTenure(0);
      return 0;
    }
  };

  const updateData = (emi) => {
    setLoanEMI(Math.round(emi));

    let totalAmount = Math.round(loanTenure * emi * 12);
    setTotalAmount(totalAmount);

    let totalInterestPayable = Math.round(totalAmount - loanAmount);
    setTotalInterest(totalInterestPayable);

    if (myChart) {
      myChart.data.datasets[0].data[0] = totalInterestPayable;
      myChart.data.datasets[0].data[1] = loanAmount;
      myChart.update();
    }
  };

  const handleCalculate = () => {
    let emi = calculateEMI(loanAmount, interestRate, loanTenure);
    if (emi != 0) {
      updateData(emi);
    }
  };

  return (
    <div className="loan-calculator">
      <div className="top">
        <form>
          <div className="group">
            <div className="title">Amount (in dollars)</div>
            <input
              type="text"
              value={loanAmount}
              ref={loanAmountInput}
              onChange={(e) => setLoanAmount(e.target.value)}
              className="loan-amount"
            />
          </div>
          <div className="group">
            <div className="title">Interest Rate</div>
            <input
              type="text"
              value={interestRate}
              ref={interestRateInput}
              onChange={(e) => setInterestRate(e.target.value)}
              className="interest-rate"
            />
          </div>
          <div className="group">
            <div className="title">Tenure (in years)</div>
            <input
              type="text"
              value={loanTenure}
              ref={loanTenureInput}
              onChange={(e) => setLoanTenure(e.target.value)}
              className="loan-tenure"
            />
          </div>
        </form>
      </div>
      <div className="result">
        <div className="left">
          <div className="loan-emi">
            <h3>Monthly Payments</h3>
            <div className="value">{loanEMI}</div>
          </div>
          <div className="total-interest">
            <h3>Interst Over Life of loan</h3>
            <div className="value">{totalInterest}</div>
          </div>
          <div className="total-amount">
            <h3>Principle & Interest Over Life of Loan</h3>
            <div className="value">{totalAmount}</div>
          </div>
          <button className="calculate-btn" onClick={handleCalculate}>
            Calculate
          </button>
        </div>
        <div className="right">
          <canvas id="myChart" width="400" height="400"></canvas>
        </div>
      </div>
    </div>
  );
};

export default LoanCalculator;
