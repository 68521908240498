import React, { useEffect } from "react";
import bg3 from "../assect/images/bg/03.jpg";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import { useLocation } from "react-router-dom";

export default function ServiceDetail({ detailData }) {
  const state = useLocation();

  useEffect(() => {
    //console.log(state?.state?.keyFeatures);
    state?.state?.keyFeatures?.map((item) => console.log(item));
  }, [state]);

  return (
    <>
      <Navbar
        navClass="defaultscroll sticky"
        logolight={true}
        menuClass="navigation-menu nav-left nav-light"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${state?.state?.image})` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
                  {state?.state?.title}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section className="section">
        <div className="container mt-10 ">
          <div className="row justify-content-center">
            <div className="col">
              <div className="section-title text-center mb-4 pb-2">
                <h4 className="title mb-3">{state?.state?.sectionTitle}</h4>
                <p className="text-muted mb-0 mx-auto">
                  {state?.state?.overview}
                </p>
              </div>
            </div>
          </div>
          <div className="text-center mb-4 pb-2">
            <h4 className="title mb-3">Description</h4>
            <p className="text-muted mb-0 mx-auto">
              {state?.state?.description}
            </p>
          </div>
          <div className="title text-center mb-4 pb-2 justify-content-center d-flex flex-column">
            <h4 className="title mb-3">Key Features</h4>
            <p className="container justify-content-center d-flex flex-column gap-3">
              {state?.state?.keyFeatures?.map((item, index) => (
                <span className="text-muted" key={index}>
                  &#x2022;<span className="mx-2">{item}</span>
                </span>
              ))}
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
