import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import {
  collection,
  getDocs,
  query,
  startAfter,
  limit,
  orderBy,
} from "firebase/firestore";
import { db } from "../config";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Spinner from "../common/loading-spinner";
import RoutesEnums from "../enums/routes.enums";
import SectionSpinner from "../common/section-loading-spinner";
import bg3 from "../assect/images/bg/03.jpg";
import "../App.css";
import { FiSearch } from "react-icons/fi";
import { Collapse } from "antd";
import SellerGuide from "./insights-and-research/sellers-guide";
import BuyersGuide from "./insights-and-research/buyers-guide";

const pageSize = 6; // Adjust the page size as needed
const maxLength = 30;

export default function Blogs() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingSection, setLoadingSection] = useState(false);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [hasBlogCreationAccess, setBlogCreationAccess] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [tags, setTags] = useState();
  const [iframeWidth, setIframeWidth] = useState(560);

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.matchMedia("(max-width: 480px)").matches
        ? 315
        : 560;
      setIframeWidth(newWidth);
    };

    // Initial call to set the initial width
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const panelStyle = {
    margin: 24,
    background: "#FFFF",
    border: "#E5E5E5 1px solid",
    borderRadius: 4,
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
  };
  const items = [
    {
      key: "1",
      label: "Sellers Guide",
      children: <SellerGuide />,
      style: panelStyle,
    },
    {
      key: "2",
      label: "Buyers Guide",
      children: (
        <p>
          <BuyersGuide />
        </p>
      ),
      style: panelStyle,
    },
  ];

  // const [allData, setAllData] = useState([]); // Store all data from all pages
  // const [totalBlogs, setTotalBlogs] = useState(0);
  // const [selectedPage, setSelectedPage] = useState(1);

  // useEffect(() => {
  //   getCollectionLength();
  // }, []);

  useEffect(() => {
    setLoading(true);
    fetchData();
    authorizedUser(localStorage.getItem("userID"));
  }, []);

  // const getCollectionLength = async () => {
  //   setLoading(true);
  //   const q = query(collection(db, "blog"), orderBy("date"));
  //   try {
  //     const querySnapshot = await getDocs(q);
  //     // Get the length of the collection
  //     const collectionLength = querySnapshot.size;
  //     const newData = querySnapshot.docs.map((doc) => ({
  //       ...doc.data(),
  //       id: doc.id,
  //     }));
  //     setAllData(newData);
  //     setTotalBlogs(collectionLength);
  //     // Call fetchData after getting the collection length
  //     fetchData();
  //   } catch (error) {
  //     console.error("Error getting collection length:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleChange = (event) => {
    event.preventDefault();
    //console.log("Value is: ", event.target.value.toLowerCase());
    setKeyword(event.target.value.toLowerCase());
    const data = [...filteredData];
    filterData(data);
  };

  const fetchData = async (title = null) => {
    const tagsDocRef = doc(db, "popularTags", "tags");
    const tagsDoc = await getDoc(tagsDocRef);

    if (tagsDoc.exists()) {
      const tagsData = tagsDoc
        .data()
        .tags.map((tag) => ({ ...tag, selected: false })); // Initialize selected property
      console.log(tagsData);
      setTags(tagsData);
    } else {
      console.log("No popular tags found!");
      setTags([]);
    }
    // let q = query(collection(db, "blog"), orderBy("date"), limit(pageSize));
    let q = query(collection(db, "blog"), orderBy("date", "desc"));
    // if (lastDoc) {
    //   // If lastDoc exists, modify the query to start after the last document
    //   q = query(
    //     collection(db, "blog"),
    //     orderBy("date"),
    //     startAfter(lastDoc),
    //     limit(pageSize)
    //   );
    // }

    const querySnapshot = await getDocs(q);

    const newData = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    //console.log(newData);

    // If the length of the fetched data is less than pageSize,
    // it means there are no more documents to fetch
    if (newData.length < pageSize) {
      setHasMoreData(false);
    }

    // Combine new data with existing data
    //setAllData((prevAllData) => [...prevAllData, ...newData]);
    // Set the data for the current page
    // if(keyword){
    //   filterData(newData)
    // }
    // else
    setCurrentPageData(newData);
    setFilteredData(newData);
    // filterData(newData)
    // Use a callback to ensure the latest value of lastDoc
    setLastDoc(() => querySnapshot.docs[querySnapshot.docs.length - 1]);
    setLoading(false);
    setLoadingSection(false);
    //console.log(currentPageData);
  };

  //   const loadMore = () => {
  //     fetchData();
  //   };

  // const calculateTotalPages = () => {
  //   return Math.ceil(totalBlogs / pageSize);
  // };

  const renderDescription = (description) => {
    const strippedDescription = description?.replace(/<[^>]*>/g, "");
    if (strippedDescription?.length <= maxLength) {
      return strippedDescription;
    } else {
      return `${strippedDescription?.slice(0, maxLength)}...`;
    }
  };

  // const renderPaginationLinks = () => {
  //   const totalPages = calculateTotalPages();
  //   const paginationLinks = [];

  //   for (let i = 1; i <= totalPages; i++) {
  //     paginationLinks.push(
  //       <li
  //         className={`page-item ${i === selectedPage ? "active" : ""}`}
  //         key={i}
  //         onClick={() => goToPage(i)}
  //       >
  //         <Link className="page-link" to="#">
  //           {i}
  //         </Link>
  //       </li>
  //     );
  //   }
  //   return paginationLinks;
  // };

  // const goToPage = (pageNumber) => {
  //   setLoading(true);
  //   const startIndex = (pageNumber - 1) * pageSize;
  //   const endIndex = startIndex + pageSize;
  //   setCurrentPageData(allData.slice(startIndex, endIndex));
  //   setSelectedPage(pageNumber);
  //   setLoading(false);
  // };
  // const authorizedUser = async (userId) => {
  //   const userRef = doc(db, "admin-access", "authorizedUsers");

  //   try {
  //     const docSnapshot = await getDoc(userRef);
  //     if (docSnapshot.exists()) {
  //       const data = docSnapshot.data();
  //       const uid = JSON.parse(userId);
  //       const access = data[uid];
  //       //console.log("data:", data[uid]);
  //       setBlogCreationAccess(access);
  //     } else {
  //       //console.log("authorizedUsers document does not exist");
  //       return false;
  //     }
  //   } catch (error) {
  //     console.error("Error checking user existence:", error);
  //     return false;
  //   }
  // };

  const authorizedUser = async (userId) => {
    const userRef = doc(db, "admin-access", "authorizedUsers");

    try {
      const docSnapshot = await getDoc(userRef);
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        const uid = JSON.parse(userId);
        const access = data[uid];
        //console.log("data:", data[uid]);
        if (access[1] === "all" || access[1] === "blogs") {
          setBlogCreationAccess(true);
        }
      } else {
        //console.log("authorizedUsers document does not exist");
        setBlogCreationAccess(false);
        return false;
      }
    } catch (error) {
      console.error("Error checking user existence:", error);
      return false;
    }
  };

  const loadData = () => {
    fetchData();
  };

  const filterData = (data) => {
    if (keyword != "") {
      const newData = data.filter((item) => {
        const title = item.title.toLowerCase();
        const key = keyword.toLocaleLowerCase();
        const tags = item.tag.toLowerCase();
        return title.includes(key) || tags.includes(key);
      });
      setFilteredData(newData);
    } else {
      //console.log(currentPageData);
      setFilteredData(currentPageData);
    }
    // console.log(filterData)
    // setCurrentPageData(tempFilterData)
  };

  const onSubmit = (event) => {
    event.preventDefault();
    //console.log(event);

    const data = filteredData;
    //console.log(data);
    filterData(data);
  };

  const resetData = (event) => {
    setKeyword("");
    event.preventDefault();
    //console.log(currentPageData);
    // setFilteredData([])
    setFilteredData(currentPageData);
    // fetchData()
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const data = filteredData;
    if (searchTerm == "") {
      setFilteredData(currentPageData);
    } else {
      // Filter data based on the search term
      const newData = data.filter((item) => {
        const title = item.title.toLowerCase();
        const key = keyword.toLocaleLowerCase();
        const tags = item.tag.toLowerCase();
        return title.includes(key) || tags.includes(key);
      });
      setFilteredData(newData);
    }
    setKeyword(event.target.value.toLowerCase());
  };

  const filterWithTags = (tag) => {
    const updatedTags = tags.map((item) => {
      if (item.tag.toLowerCase() === tag.toLowerCase()) {
        const isSelected = item.hasOwnProperty("selected")
          ? item.selected
          : false;
        return { ...item, selected: !isSelected };
      } else {
        return item;
      }
    });

    const allDeselected = updatedTags.every((tag) => !tag.selected);
    if (allDeselected) {
      setFilteredData(currentPageData);
    } else {
      const filtered = currentPageData.filter((item) => {
        const tagsArray = item.tag.toLowerCase().split(",");
        return tagsArray.some(
          (t) =>
            updatedTags.find(
              (tag) => tag.tag.toLowerCase() === t.trim().toLowerCase()
            )?.selected
        );
      });
      setFilteredData(filtered);
    }

    setTags(updatedTags);
  };

  return (
    <>
      {loading && <Spinner />}
      <Navbar
        navClass="defaultscroll sticky"
        logolight={true}
        menuClass="navigation-menu nav-left nav-light"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${bg3})` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
                  Insight & Research
                </h5>
              </div>
            </div>
          </div>
          <div className="position-middle-bottom">
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                <li className="breadcrumb-item">
                  <Link to="/">Piertop</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Insight & Research
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
      <section>
        {/* Guides Section */}
        {/* <div className="col mt-5">
          <div className="section-title text-center mb-2 pb-2">
            <h4 className="title mb-2">Guides</h4>
          </div>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <Collapse size="large" items={items} />
          </div>
        </div> */}

        {/* Research Section */}
        {/* <div className="container mt-100 mb-10">
          <div className="row justify-content-center">
            <div className="col">
              <div className="section-title text-center mb-2 pb-2">
                <h4 className="title mb-2">Research</h4>
                <p>
                  PierTop is committed to delivering excellence in all facets of
                  your real estate needs. PierTop bridges passion and expertise
                  while holding true to the belief that every decision should
                  promote ministry. Created with the goal of alleviating
                  religious leaders of the need to deviate from ministry, we
                  exploit synergy between industry proficiencies and theoretical
                  competencies to deliver superior results by providing
                  customized real estate solutions that align with your
                  distinctive vision and mission. We take a holistic approach to
                  religious properties to ensure that each transaction promotes
                  your long-term vision. As result we offer a range of services,
                  including selling, buying, leasing, consulting, investing,
                  property valuation, property management, and sustainability
                  services, to support your calling.
                </p>
              </div>
            </div>
          </div>
          <div className="row g-4"></div>
        </div> */}

        {/* Videos Section */}
        {/* <div style={{ backgroundColor: "#0c1d56" , borderRadius: "10px" }}>
            <div style={{ padding: "50px", marginTop: "60px" }} className="justify-content-center">
              <div className="col">
                <div className="section-title text-center mb-2 pb-2">
                  <h4 style={{ color: "white" }} className="title mb-2">Videos</h4>
                  <div className="flex-column" style={{ display: "flex" , alignItems: "center" , gap: "50px" }}>
                    <iframe
                      width={iframeWidth}
                      height="315"
                      src="https://media.istockphoto.com/id/1433471040/video/church-steeple-at-sunrise.mp4?s=mp4-640x640-is&k=20&c=-qKZBAlFxvxQPVhwgdSsrgNDUERUEP2BI7BV09EQ3PY="
                      title="Dummy video"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                    <iframe
                      width={iframeWidth}
                      height="315"
                      src="https://cdn.pixabay.com/vimeo/294031798/church-18599.mp4?width=640&hash=817fa77751e249756d2607fc55b3f25b9484bbb5"
                      title="Dummy video"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
        </div> */}
      </section>
      <div
        className="tab-content bg-white"
        style={{
          marginTop: "30px",
        }}
      >
        <div className="card border-0 active">
          <form className="card-body text-start">
            <div className="registration-form text-dark text-start">
              <div className="row g-lg-0 d-flex justify-content-center">
                <div className="col-lg-3 col-md-9 col-6">
                  <h3
                    className="title mb-5"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Insight And Research
                  </h3>
                  <div className="mb-3">
                    <div className="filter-search-form position-relative filter-border">
                      <FiSearch className="fea icon-ex-md icons" />
                      <input
                        name="name"
                        type="text"
                        id="job-keyword"
                        className="form-control filter-input-box bg-light border-0"
                        placeholder="Search your keywords"
                        value={keyword}
                        onChange={handleSearch}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-1 col-md-3 col-4">
                  <button
                    id="search"
                    name="search"
                    style={{ height: "48px" }}
                    className="btn btn-primary rounded-3"
                    onChange={onSubmit}
                  >
                    Search
                  </button>
                </div> */}
                {/* <div className="col-lg-1 col-md-3 col-4">
                  <button
                    id="search"
                    name="search"
                    style={{ height: "48px" }}
                    className="btn btn-primary searchbtn w-100"
                    onClick={resetData}
                  >
                    Reset
                  </button>
                </div> */}
              </div>
            </div>
          </form>
        </div>
      </div>
      <div>
        <div className="container "></div>
      </div>
      <div className="position-relative">
        <div className="overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      {localStorage.getItem("accessToken") && (
        <div className="row justify-content-center pt-4">
          {hasBlogCreationAccess ? (
            <button
              onClick={() => {
                navigate(RoutesEnums.CREATE_BLOG);
              }}
              className="btn btn-primary"
              style={{ width: "200px" }}
            >
              + Create Blog
            </button>
          ) : null}
        </div>
      )}
      <section
        className="section"
        style={{
          padding: "50px 0px",
        }}
      >
        <div className="largercontainer">
          <div className="row g-2 col-lg-9">
            {filteredData.length != 0 ? (
              filteredData.map((item, index) => {
                return (
                  <div className="col-10 col-md-4" key={index}>
                    <div className="card blog blog-primary shadow rounded-3 overflow-hidden border-0">
                      <div className="card-img blog-image position-relative overflow-hidden rounded-0">
                        <div className="position-relative overflow-hidden">
                          <img
                            src={item.image}
                            className="img-fluid img-fixed"
                            style={{
                              objectFit: "contain",
                              aspectRatio: "1/1",
                            }}
                            alt=""
                          />
                          <div className="card-overlay"></div>
                        </div>

                        <div className="blog-tag p-3" style={{ marginTop: "2rem", display: "flex", gap : "1rem" }}>
                          {item?.tag?.split(",").map((tag, index) => (
                            <Link
                              key={index}
                              className="badge badge-link bg-primary ms-1"
                              to={`/tags/${tag?.trim()}`} // Assuming you want to link to a specific tag page
                            >
                              {tag?.trim()}
                            </Link>
                          ))}
                        </div>
                      </div>

                      <div className="card-body content p-0">
                        <div className="p-4">
                          <Link
                            to={`/blog-detail/${item.id}`}
                            className="title fw-medium fs-5 text-dark"
                          >
                            {renderDescription(item.title)}
                          </Link>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: renderDescription(item.description),
                            }}
                            className="text-muted mt-2 text-ellipsis"
                          ></p>

                          <Link
                            to={`/blog-detail/${item.id}`}
                            className="text-dark read-more"
                          >
                            Read More{" "}
                            <i className="mdi mdi-chevron-right align-middle"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>No Blogs Found</div>
            )}
            {loadingSection && <SectionSpinner />}
          </div>
          <div
            className="d-flex"
            style={{ maxHeight: "14rem", flexDirection: "column", gap: "1rem" }}
          >
            <h3 className="d-flex" style={{ justifyContent: "center" }}>
              Tags
            </h3>
            <div
              className="d-flex flex-wrap"
              style={{ gap: "1rem", justifyContent: "center" }}
            >
              {tags
                ?.filter((item) => item.count >= 1)
                .sort((a, b) => b.count - a.count)
                .map((item, index) => (
                  <div
                    key={index}
                    className={`bg-${
                      item.selected ? "black" : "primary"
                    } text-white`}
                    style={{
                      padding: "8px",
                      height: "40px",
                      width: "100px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      transition: "background-color 0.3s ease",
                      cursor: "pointer",
                      position: "relative",
                      borderRadius: "100px", // Rounded corners
                      flexShrink: 0, // Prevent shrinking
                    }}
                    onClick={() => filterWithTags(item.tag)}
                  >
                    <p style={{ margin: 0 }}>{item?.tag}</p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
