import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import TinySlider from "tiny-slider-react";
import { db } from "../../config";
import { collection, getDoc } from "firebase/firestore";
import { doc } from "firebase/firestore";
import { toast } from "react-toastify";
import { toastUtil } from "../../utils/toast.utils";
import Spinner from "../../common/loading-spinner";

// import image1 from "../../assect/images/property/single/1.jpg";
// import image2 from "../../assect/images/property/single/2.jpg";
// import image3 from "../../assect/images/property/single/3.jpg";
// import image4 from "../../assect/images/property/single/4.jpg";
// import image5 from "../../assect/images/property/single/5.jpg";
import bg2 from "../../assect/images/bg/02.jpg";

import Navbar from "../../components/navbar";
// import ProprtySlider from "../../components/propertySlider";
// import Lightbox from "react-18-image-lightbox";
import "../../../node_modules/react-18-image-lightbox/style.css";
import Footer from "../../components/footer";

export default function PropertyDetails() {
  const params = useParams();
  const id = params.id;

  const settings = {
    container: ".tiny-one-item",
    items: 1,
    controls: true,
    mouseDrag: true,
    loop: true,
    rewind: true,
    // autoplay: true,
    // autoplayButtonOutput: false,
    // autoplayTimeout: 3000,
    navPosition: "bottom",
    controlsText: [
      '<i class="mdi mdi-chevron-left "></i>',
      '<i class="mdi mdi-chevron-right"></i>',
    ],
    nav: false,
    speed: 300,
    gutter: 0,
  };

  // const [currentImageIndex, setCurrentImageIndex] = useState(0);
  // const [open, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [property, setProperty] = useState({});
  // const images = [image1, image2, image3, image4, image5];

  // const handleMovePrev = () => {
  //   setCurrentImageIndex(
  //     (prevIndex) => (prevIndex + images.length - 1) % images.length
  //   );
  // };

  // const handleMoveNext = () => {
  //   setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  // };

  // const handleImageClick = (index) => {
  //   setCurrentImageIndex(index);
  //   setIsOpen(true);
  // };

  //const currentImage = images[currentImageIndex];

  const fetchProperty = async () => {
    try {
      setLoading(true);
      const docSnap = await getDoc(doc(db, "properties", id));
      //console.log("Featured Property data: ", docSnap.data());
      setProperty(docSnap.data());
    } catch (err) {
      toast.error(err.message, toastUtil);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProperty();
  }, []);

  return (
    <>
      <Navbar
        navClass="defaultscroll sticky"
        logolight={true}
        menuClass="navigation-menu nav-left nav-light"
      />

      {loading ? (
        <div
          className=""
          style={{ background: "white", width: "100wh", height: "100vh" }}
        >
          <Spinner />
        </div>
      ) : (
        <>
          <section
            className="bg-half-170 d-table w-100"
            style={{ backgroundImage: `url(${bg2})` }}
          >
            <div className="bg-overlay bg-gradient-overlay-2"></div>
            <div className="container">
              <div className="row mt-5 justify-content-center">
                <div className="col-12">
                  <div className="title-heading text-center">
                    <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark mt-4">
                      Property Details
                    </h5>
                  </div>
                </div>
              </div>
              <div className="position-middle-bottom">
                <nav aria-label="breadcrumb" className="d-block">
                  <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                    <li className="breadcrumb-item">
                      <Link to="/">PierTop</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/buy">Properties</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Property Details
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </section>
          <div className="position-relative">
            <div className="shape overflow-hidden text-white">
              <svg
                viewBox="0 0 2880 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
          </div>

          <section className="section">
            {property && (
              <div className="container">
                <div className="row g-4">
                  <div className="col-lg-8 col-md-7 col-12">
                    <div className="section-title">
                      <h4 className="title mb-0">{property.address}</h4>

                      {/* Single Image Work */}
                      {/* <div className="card map border-0">
                        <div className="card-body p-0">
                          <img
                            src={property.image}
                            className="img-fluid"
                            style={{
                              border: "1.5px solid #f0f0f0",
                              objectFit: "cover",
                              aspectRatio: "9/6",
                            }}
                            alt="Church"
                          />
                        </div>
                      </div> */}

                      <div className="container">
                        <div className="row justify-content-center">
                          <div className="col-lg-10">
                            {/* Images in Slider Only */}
                            {/* <div className="tiny-one-item">
                              <TinySlider settings={settings}>
                                {property.image.map((item, index) => {
                                  return (
                                    <div className="tiny-slide" key={index}>
                                      <div className="m-md-2">
                                        <img
                                          src={item}
                                          className="shadow rounded-3 img-fluid"
                                          alt="Property"
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                              </TinySlider>
                            </div> */}

                            {/* Images + Videos in Slider */}
                            <div className="tiny-one-item">
                              <TinySlider settings={settings}>
                                {[
                                  {
                                    type: "video",
                                    src: property.video,
                                  }, // Assuming videoUrl is the variable containing the video URL
                                  ...property.image.map((imageUrl, index) => ({
                                    type: "image",
                                    src: imageUrl,
                                  })),
                                ].map((item, index) => {
                                  return (
                                    <div className="tiny-slide" key={index}>
                                      <div className="m-md-2">
                                        {item.type === "image" ? (
                                          <img
                                            src={item.src}
                                            className="shadow rounded-3 img-fluid"
                                            alt="Property"
                                            style={{
                                              aspectRatio: "1/1",
                                            }}
                                          />
                                        ) : item.type === "video" ? (
                                          <video
                                            controls
                                            className="shadow rounded-3 img-fluid"
                                            style={{
                                              aspectRatio: "1/1",
                                            }}
                                          >
                                            <source
                                              src={item.src}
                                              type="video/mp4"
                                            />
                                            Your browser does not support the
                                            video tag.
                                          </video>
                                        ) : null}
                                      </div>
                                    </div>
                                  );
                                })}
                              </TinySlider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-5 col-12">
                    <div
                      className="rounded-3 shadow bg-white p-4"
                      style={{
                        top: "80px",
                      }}
                    >
                      <h5 className="mb-3">Price:</h5>

                      <div className="d-flex align-items-center justify-content-between">
                        <h5 className="mb-0">$ {property.price}</h5>
                        <span className="badge bg-primary">
                          {property.propertyType}
                        </span>
                      </div>

                      <div className="">
                        <div className="d-flex align-items-center justify-content-between mt-2">
                          <span className="small text-muted">Land Area</span>
                          <span className="small">
                            {property.landArea} ± acre
                          </span>
                        </div>

                        <div className="d-flex align-items-center justify-content-between mt-2">
                          <span className="small text-muted">Building</span>
                          <span className="small">
                            {property.building} ± sq ft
                          </span>
                        </div>

                        <div className="d-flex align-items-center justify-content-between mt-2">
                          <span className="small text-muted">Seating</span>
                          <span className="small">{property.seating} ±</span>
                        </div>

                        <div className="d-flex align-items-center justify-content-between mt-2">
                          <span className="small text-muted">Parking</span>
                          <span className="small">{property.parking}</span>
                        </div>

                        <div className="d-flex align-items-center justify-content-between mt-2">
                          <span className="small text-muted">Agent</span>
                          <span className="small">{property.agent}</span>
                        </div>

                        <div className="d-flex align-items-center justify-content-between mt-2">
                          <span className="small text-muted">Phone</span>
                          <span className="small">{property.phone}</span>
                        </div>
                      </div>

                      {/* <div className="d-flex mt-3">
                  <Link to="#" className="btn btn-primary w-100 me-2">
                    Book Now
                  </Link>
                  <Link to="#" className="btn btn-primary w-100">
                    Offer now
                  </Link>
                </div> */}
                    </div>
                  </div>

                  <p
                    dangerouslySetInnerHTML={{
                      __html: property.additionalComments,
                    }}
                    className="mt-5"
                  ></p>
                </div>
              </div>
            )}

            {/* <div className="container mt-100 mt-60">
              <div className="row justify-content-center">
                <div className="col">
                  <div className="section-title text-center mb-4 pb-2">
                    <h4 className="title mb-3">Related Properties</h4>
                    <p className="text-muted para-desc mb-0 mx-auto">
                      A great plateform to buy, sell and rent your properties
                      without any agent or commisions.
                    </p>
                  </div>
                </div>
              </div>

              <ProprtySlider />
            </div> */}
          </section>
        </>
      )}
      <Footer />
      {/* {open && (
        <Lightbox
          mainSrc={currentImage}
          prevSrc={
            images[(currentImageIndex + images.length - 1) % images.length]
          }
          nextSrc={images[(currentImageIndex + 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )} */}
    </>
  );
}
