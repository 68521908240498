import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import { Progress } from "antd";
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import ReCAPTCHA from "react-google-recaptcha";
import { db, storage } from "../config";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Spinner from "../common/loading-spinner";
import { toastUtil } from "../utils/toast.utils";
import RoutesEnums from "../enums/routes.enums";
import bg4 from "../assect/images/bg/04.jpg";
import "react-quill/dist/quill.snow.css";
import "../App.css";

export default function AddProperty() {
  let navigate = useNavigate();
  const recaptcha = useRef();
  const MAX_IMG_SIZE = 5;
  const currentDate = new Date();

  const [percent, setPercent] = useState(0);
  const [videoPercent, setVideoPercent] = useState(0);
  const [showPercentBar, setShowPercentBar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [desc, setDesc] = useState("");
  const [formData, setFormData] = useState({
    address: "",
    propertyCatogery: "Featured",
    landArea: "",
    price: 0,
    propertyType: "For Sale",
    building: 0,
    seating: "",
    parking: "",
    agent: "",
    phone: "",
    image: [],
    video: "",
    additionalComments: "",
  });
  const [isRequired, setIsRequired] = useState(true);
  const [imageData, setImageData] = useState([]);

  useEffect(() => {
    //console.log("Image data is: ", imageData);
  }, [imageData]);

  // const handleUpload = () => {
  //   setLoading(true);
  //   return new Promise((resolve, reject) => {
  //     if (!formData.image) {
  //       if (!isRequired) {
  //         resolve(
  //           "https://firebasestorage.googleapis.com/v0/b/piertop-jaques.appspot.com/o/blogImages%2Fchurch.jpg?alt=media&token=71036057-5216-4c03-b3e1-75131251f510"
  //         );
  //       } else {
  //         alert("Please choose a file first!");
  //         reject("No file selected");
  //       }
  //       return;
  //     }

  //     const storageRef = ref(storage, `/blogImages/${formData.imageName}`);
  //     const uploadTask = uploadBytesResumable(storageRef, formData.image);

  //     uploadTask.on(
  //       "state_changed",
  //       (snapshot) => {
  //         const percent = Math.round(
  //           (snapshot.bytesTransferred / snapshot.totalBytes) * 100
  //         );
  //         setShowPercentBar(true);
  //         setPercent(percent);
  //       },
  //       (err) => {
  //         console.error(err);
  //         setLoading(false);
  //         reject(err);
  //       },
  //       () => {
  //         getDownloadURL(uploadTask.snapshot.ref)
  //           .then((url) => {
  //             //console.log(url);
  //             resolve(url);
  //           })
  //           .catch((err) => {
  //             console.error(err);
  //             reject(err);
  //           });
  //       }
  //     );
  //   });
  // };

  const handleMultipleImagesUpload = () => {

    setLoading(true);

    return new Promise((resolve, reject) => {
      if (!imageData.image || imageData.image.length === 0) {
        if (!isRequired) {
          resolve([
            "https://firebasestorage.googleapis.com/v0/b/piertop-jaques.appspot.com/o/blogImages%2Fchurch.jpg?alt=media&token=71036057-5216-4c03-b3e1-75131251f510",
          ]);
        } else {
          alert("Please choose at least one file!");
          reject("No file selected");
        }
        // setLoading(false);
        return;
      }

      const uploadPromises = imageData.image.map((image, index) => {
        return new Promise((resolveImage, rejectImage) => {
          const storageRef = ref(storage, `/propertyImages/${image.name}`);
          const uploadTask = uploadBytesResumable(storageRef, image.file);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              setShowPercentBar(true);
              setPercent(percent);
            },
            (err) => {
              console.error(err);
              // setLoading(false);
              rejectImage(err);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref)
                .then((url) => {
                  //console.log("Image URL is: ", url);
                  resolveImage(url);
                })
                .catch((err) => {
                  console.error(err);
                  rejectImage(err);
                });
            }
          );
        });
      });

      Promise.all(uploadPromises)
        .then((urls) => {
          //console.log("All Images URL are: ", urls);
          resolve(urls);
        })
        .catch((err) => {
          reject(err);
        });
      // .finally(() => {
      //   setLoading(false);
      // });
    });
  };

  const handleVideoUpload = () => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      if (!formData.video) {
        if (!isRequired) {
          resolve(
            "https://cdn.pixabay.com/vimeo/294031798/church-18599.mp4?width=640&hash=817fa77751e249756d2607fc55b3f25b9484bbb5" // Provide a default video URL if necessary
          );
        } else {
          alert("Please choose a file first!");
          reject("No file selected");
        }
        return;
      }

      const storageRef = ref(storage, `/videos/${formData.videoName}`);
      const uploadTask = uploadBytesResumable(storageRef, formData.video);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setShowPercentBar(true);
          setVideoPercent(percent);
        },
        (err) => {
          console.error(err);
          setLoading(false);
          reject(err);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((url) => {
              //console.log("Video uploaded successfully:", url);
              setLoading(false);
              resolve(url);
            })
            .catch((err) => {
              console.error("Error getting download URL:", err);
              reject(err);
            });
        }
      );
    });
  };

  const handleChange = (e) => {

    let { name, value, files } = e?.target;

    if (name === "propertyCatogery" && value === "Wanted") {
      setIsRequired(false);
    }

    if (name === "image" && files.length > 0) {
      const fileSize = files.item(0).size;
      const fileSizeMb = fileSize / 1024 ** 2;

      if (fileSizeMb > MAX_IMG_SIZE) {
        alert(`Too big! Select an image under ${MAX_IMG_SIZE} MB!`);
        name = null;
        value = null;
        files = null;
        return false;
      }
      const imageName = files[0]?.name;

      setFormData((prevData) => ({
        ...prevData,
        imageName,
        [name]: files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleMultipleImagesChange = (e) => {

    let { name, value, files } = e?.target;

    if (files.length > 0) {
      const newImages = [];
      for (let i = 0; i < files.length; i++) {
        const fileSize = files[i].size;
        const fileSizeMb = fileSize / 1024 ** 2;

        if (fileSizeMb > MAX_IMG_SIZE) {
          alert(
            `Image ${
              i + 1
            } is too big! Select an image under ${MAX_IMG_SIZE} MB!`
          );
          name = null;
          value = null;
          files = null;
          return false;
        }

        newImages.push({
          name: files[i].name,
          file: files[i],
        });
      }

      //console.log("Images are : ", newImages);

      setImageData((prevData) => ({
        ...prevData,
        [name]: newImages,
      }));
    }
  };

  const handleVideoChange = (e) => {


    let { name, value, files } = e?.target;

    if (name === "video" && files.length > 0) {
      const file = files[0]; // Get the first file from the input

      const videoName = file.name;

      setFormData((prevData) => ({
        ...prevData,
        videoName,
        [name]: file,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      toast.error("Please verify the Recaptcha!", toastUtil);
      return;
    }
    try {
      formData.additionalComments = desc;
      //console.log("Form data is: ", formData);
      //const imageUrl = await handleUpload();
      const imagesUrl = await handleMultipleImagesUpload();
      const videoUrl = await handleVideoUpload();

      const docRef = await addDoc(collection(db, "properties"), {
        address: formData.address,
        propertyCatogery: formData.propertyCatogery,
        landArea: formData.landArea,
        price: parseInt(formData.price),
        propertyType: formData.propertyType,
        building: parseInt(formData.building),
        seating: formData.seating,
        parking: formData.parking,
        agent: formData.agent,
        phone: formData.phone,
        image: imagesUrl,
        video: videoUrl,
        additionalComments: formData.additionalComments,
        date: currentDate,
      });
      //console.log("Document written with ID: ", docRef.id);
    } catch (error) {
      setLoading(false);
      toast.error(error.message, toastUtil);
      console.error("Error adding document: ", error);
    } finally {
      toast.success("Property Added", toastUtil);
      setLoading(false);
      //setFormData(null);
      navigate(RoutesEnums.BUY);
    }
  };

  return (
    <>
      {loading && <Spinner />}
      <Navbar
        navClass="defaultscroll sticky"
        logolight={true}
        menuClass="navigation-menu nav-left nav-light"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${bg4})` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container end-section">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
                  Add Property
                </h5>
              </div>
            </div>
          </div>
          <div className="position-middle-bottom">
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                <li className="breadcrumb-item">
                  <Link to="/">Piertop</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Add Property
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section className="section pb-0">
        <div className="container end-section">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="p-4 rounded-3 shadow">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-9">
                      <div className="mb-3">
                        <label className="form-label">
                          Address <span className="text-danger">*</span>
                        </label>
                        <input
                          name="address"
                          id="property-address"
                          type="text"
                          className="form-control"
                          placeholder="Address"
                          onChange={handleChange}
                          value={formData.address}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="mb-3">
                        <label className="form-label">
                          Category <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-select form-control border"
                          id="propertyCatogery"
                          name="propertyCatogery"
                          onChange={handleChange}
                          value={formData.propertyCatogery}
                          required
                        >
                          <option value="Featured">Featured</option>
                          <option value="Wanted">Wanted</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label">
                          Land Area <span className="text-danger">*</span>
                        </label>
                        <input
                          name="landArea"
                          id="property-landArea"
                          type="text"
                          className="form-control"
                          placeholder="Area in acres"
                          onChange={handleChange}
                          value={formData.landArea}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label">
                          Price <span className="text-danger">*</span>
                        </label>
                        <input
                          name="price"
                          id="property-price"
                          type="number"
                          min="1"
                          className="form-control"
                          placeholder="Price in dollars"
                          onChange={handleChange}
                          value={formData.price}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label">
                          Type <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-select form-control border"
                          id="propertyType"
                          name="propertyType"
                          onChange={handleChange}
                          value={formData.propertyType}
                          required
                        >
                          <option value="For Sale">For Sale</option>
                          <option value="For Lease">For Lease</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label">
                          Building <span className="text-danger">*</span>
                        </label>
                        <input
                          name="building"
                          id="property-building"
                          type="number"
                          min="1"
                          className="form-control"
                          placeholder="Area in sqft"
                          onChange={handleChange}
                          value={formData.building}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label">
                          Seating{" "}
                          {isRequired && <span className="text-danger">*</span>}
                        </label>
                        <input
                          name="seating"
                          id="property-seating"
                          type="text"
                          className="form-control"
                          placeholder="Seating capacity"
                          onChange={handleChange}
                          value={formData.seating}
                          required={isRequired}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label">
                          Parking{" "}
                          {isRequired && <span className="text-danger">*</span>}
                        </label>
                        <input
                          name="parking"
                          id="property-parking"
                          type="text"
                          className="form-control"
                          placeholder="Parking capacity"
                          onChange={handleChange}
                          value={formData.parking}
                          required={isRequired}
                        />
                      </div>
                    </div>

                    <div className="col-md-5">
                      <div className="mb-3">
                        <label className="form-label">
                          Agent <span className="text-danger">*</span>
                        </label>
                        <input
                          name="agent"
                          id="property-agent"
                          type="text"
                          className="form-control"
                          placeholder="Agent Name"
                          onChange={handleChange}
                          value={formData.agent}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-7">
                      <div className="mb-3">
                        <label className="form-label">
                          Phone Number <span className="text-danger">*</span>
                        </label>
                        <input
                          name="phone"
                          id="property-phone"
                          type="text"
                          className="form-control"
                          placeholder="Phone Number"
                          onChange={handleChange}
                          value={formData.phone}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="mb-3">
                        <label className="form-label">
                          Upload Image
                          {isRequired && <span className="text-danger">*</span>}
                        </label>
                        <input
                          name="image"
                          id="property-image"
                          type="file"
                          accept=".jpg,.jpeg,.png"
                          className="form-control"
                          onChange={handleMultipleImagesChange}
                          defaultValue={formData.image}
                          multiple
                          required={isRequired}
                        />
                        {showPercentBar && (
                          <span>
                            <Progress percent={percent} size="small" />
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="mb-3">
                        <label className="form-label">
                          Upload Video
                          {isRequired && <span className="text-danger">*</span>}
                        </label>
                        <input
                          name="video"
                          id="property-video"
                          type="file"
                          accept="video/*"
                          className="form-control"
                          onChange={handleVideoChange}
                          defaultValue={formData.video}
                          required={isRequired}
                        />
                        {showPercentBar && (
                          <span>
                            <Progress percent={videoPercent} size="small" />
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="mb-3">
                        <label className="form-label">
                          Description
                          <span className="text-danger">*</span>
                        </label>
                        <ReactQuill
                          className="h-[500px]"
                          theme="snow"
                          name="additionalComments"
                          onChange={setDesc}
                          value={desc}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <ReCAPTCHA
                    ref={recaptcha}
                    sitekey={process.env.REACT_APP_SITE_KEY}
                  />

                  {/* <div className="row mt-3">
                    <div className="col-12">
                      <div className="d-grid">
                        <button
                          id="property-submit"
                          name="property-submit"
                          className="btn btn-primary"
                          onClick={() => {
                            handleMultipleImagesUpload();
                          }}
                        >
                          Upload Images
                        </button>
                      </div>
                    </div>
                  </div> */}

                  <div className="row mt-3">
                    <div className="col-12">
                      <div className="d-grid">
                        <button
                          type="submit"
                          id="property-submit"
                          name="property-submit"
                          className="btn btn-primary"
                        >
                          Add Property
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="container-fluid mt-100 mt-60">
                <div className="row">
                    <div className="col-12 p-0">
                        <div className="card map border-0">
                            <div className="card-body p-0">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin" style={{border:"0" }} title="Townter" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
      </section>
      <Footer />
    </>
  );
}
