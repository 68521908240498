import React, { useState } from "react";
import { Link } from "react-router-dom";

import bg3 from "../assect/images/website/14.jpg";
import heroImg from "../assect/images/hero.jpg";
import dots from "../assect/images/svg/dots.svg";
import image1 from "../assect/images/1.jpg";
import map from "../assect/images/map.png";
import company from "../assect/images/company.jpg";
import church from "../assect/images/website/15.jpg";
import ourMission from "../assect/images/website/16.jpg";
import ourVision from "../assect/images/website/17.jpg";
import ourCompany from "../assect/images/website/18.jpg";
import avatar from "../assect/images/website/19.jpg";

import Navbar from "../components/navbar";
import About from "../components/about";
import Broker from "../components/broker";
import Blog from "../components/blog";
import GetInTuch from "../components/getInTuch";

import ModalVideo from "react-modal-video";
import "../../node_modules/react-modal-video/css/modal-video.css";

import CountUp from "react-countup";
import Footer from "../components/footer";

export default function AboutUs() {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <Navbar
        navClass="defaultscroll sticky"
        logolight={true}
        menuClass="navigation-menu nav-left nav-light"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${bg3})` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                {/* <p className="text-white-50 para-desc mx-auto mb-0">
                  Our story: Piertop
                </p> */}
                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
                  About Us
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section className="section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div className="about-left center-items">
                <div className="position-relative shadow p-2 rounded-top-pill rounded-5 bg-white img-one">
                  <img
                    src={church}
                    className="img-fluid rounded-top-pill rounded-5"
                    alt=""
                  />
                </div>

                {/* <div className="img-two shadow rounded-3 overflow-hidden p-2 bg-white">
                  <img src={image1} className="img-fluid rounded-3" alt="" />
                </div> */}
              </div>
            </div>

            <div className="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title ms-lg-5">
                <h4 className="title mb-3">
                  We are about Housing God’s people
                </h4>
                <p className="text-muted para-desc mb-0">
                  PierTop is committed to delivering excellence in all facets of
                  your real estate needs. PierTop bridges passion and expertise
                  while holding true to the belief that every decision should
                  promote ministry. Created with the goal of alleviating
                  religious leaders of the need to deviate from ministry, we
                  exploit synergy between industry proficiencies and theoretical
                  competencies to deliver superior results by providing
                  customized real estate solutions that align with your
                  distinctive vision and mission. We take a holistic approach to
                  religious properties to ensure that each transaction promotes
                  your long-term vision. As result we offer a range of services,
                  including selling, buying, leasing, consulting, investing,
                  property valuation, property management, and sustainability
                  services, to support your calling.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-100 mt-60">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title">
                <h4 className="title mb-3">Our mission is ministry</h4>
                <p className="text-muted para-desc mb-0">
                  We exist to fuel your ministry. Our mission is to empower you
                  by providing exceptional real estate solutions; ensuring that
                  you have the ideal spaces to thrive and fulfill your sacred
                  missions and create lasting positive impacts within your
                  communities and throughout the world.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="about-right center-items">
                <div className="position-relative shadow p-2 rounded-top-pill rounded-5 bg-white img-one">
                  <img
                    src={ourMission}
                    className="img-fluid rounded-top-pill rounded-5"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-100 mt-60">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div className="about-left center-items">
                <div className="position-relative shadow p-2 rounded-top-pill rounded-5 bg-white img-one">
                  <img
                    src={ourVision}
                    className="img-fluid rounded-top-pill rounded-5"
                    alt=""
                  />
                </div>

                {/* <div className="img-two shadow rounded-3 overflow-hidden p-2 bg-white">
                  <img src={vision} className="img-fluid rounded-3" alt="" />
                </div> */}
              </div>
            </div>

            <div className="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title ms-lg-5">
                <h4 className="title mb-3">
                  Our vision is the success of your vision
                </h4>
                <p className="text-muted para-desc mb-0">
                  Our unwavering vision is to be a cornerstone in supporting and
                  enhancing the growth and vitality of faith-based
                  organizations. We envision a world where ministries have
                  access to the perfect property, enabling them to expand their
                  reach, deepen their impact, and thrive in their sacred
                  callings. We aim to be the foremost innovators in the realm of
                  religious real estate, dedicated to providing the perfect
                  spaces and solutions that empower every ministry to not only
                  endure but to truly flourish in their sacred mission. We are
                  committed to a world where spiritual growth, community, and
                  lasting impact are at the heart of our endeavors, leaving an
                  indelible mark on the lives of those we serve.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-100 mt-60">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title">
                <h4 className="title mb-3">The company</h4>
                <p className="text-muted para-desc mb-0">
                  Piertop is founded on the yearning and zeal to serve you
                  through real estate and support you in the spreading of The
                  Good News. Driven by the need to do the most good we are
                  committed to serving religious leaders whose work have a
                  positive impact on their congregation, their community and
                  society at large. More pointedly we serve those who are tasked
                  with the mission of equipping people to live well, bless
                  others, and make a difference in the world. In helping those
                  who do the highest good Piertop has acquire its identity, and
                  purpose.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="about-right center-items">
                <div className="position-relative shadow p-2 rounded-top-pill rounded-5 bg-white img-one">
                  <img
                    src={ourCompany}
                    className="img-fluid rounded-top-pill rounded-5"
                    alt=""
                  />
                </div>

                {/* <div className="img-two shadow rounded-3 overflow-hidden p-2 bg-white">
                  <img src={image1} className="img-fluid rounded-3" alt="" />
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-100 mt-60">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div className="about-left center-items">
                <div className="position-relative shadow p-2 rounded-top-pill rounded-5 bg-white img-one">
                  <img
                    src={avatar}
                    className="img-fluid rounded-top-pill rounded-5"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title ms-lg-5">
                <h4 className="title mb-3">Leadership (Jacques Pierrilus)</h4>
                <p className="text-muted para-desc mb-0">
                  Jacques Pierrilus is the founder of Piertop. His most
                  important life work is his investment in his family. He is
                  active in his local congregation and serves or have served as
                  a church planter, men ministry leader, youth leader, speaker,
                  board member, treasurer, and development member. Jacques has
                  professionally served in the real estate industry since 2004
                  in the areas of brokerage, financing, and investment. In his
                  professional endeavors, he aims to provide unparallel service
                  coupled with integrity, all the while inspiring those, he
                  interacts with to share The Good News and live well.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container-fluid bg-building-pic mt-100 mt-60">
          <div
            className=" opacity-25 position-absolute w-100 h-100 top-0 start-0"
            style={{
              backgroundImage: `url(${map})`,
              backgroundPosition: "center",
            }}
          ></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col">
                <div className="section-title text-center mb-4 pb-2">
                  <h4 className="title mb-3">Trusted by more than 10K users</h4>
                  <p className="text-muted para-desc mb-0 mx-auto">
                    A great plateform to buy, sell and rent your properties
                    without any agent or commisions.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-4 py-3">
                <div className="counter-box text-center">
                  <h1 className="mb-0 fw-semibold">
                    <CountUp start={0} end={1548} className="counter-value" />+
                  </h1>
                  <h6 className="counter-head text-muted fw-normal">
                    Investment
                  </h6>
                </div>
              </div>

              <div className="col-4 py-3">
                <div className="counter-box text-center">
                  <h1 className="mb-0 fw-semibold">
                    <CountUp start={0} end={25} className="counter-value" />+
                  </h1>
                  <h6 className="counter-head text-muted fw-normal">Awards</h6>
                </div>
              </div>

              <div className="col-4 py-3">
                <div className="counter-box text-center">
                  <h1 className="mb-0 fw-semibold">
                    <CountUp start={0} end={9} className="counter-value" />+
                  </h1>
                  <h6 className="counter-head text-muted fw-normal">
                    Profitability
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="container mt-100 mt-60">
          <GetInTuch />
        </div>
      </section>
      <Footer />
    </>
  );
}
